import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface dataDialog {
  img: string,
  title: string,
  subtitle: string,
  explication: string,
  buttonLabel: string,
  buttonLabelValider?: string,
}

@Component({
  selector: 'core-erreur-popup',
  standalone: true,
  imports: [MatButtonModule, MatCardModule, NgIf],
  templateUrl: './erreur-popup.component.html',
  styleUrl: './erreur-popup.component.scss'
})
export class ErreurPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<ErreurPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataDialog,
  ) {}

  closeDialog(result?: true): void {
    this.dialogRef.close(result);
  }

}
